import dayjs, { Dayjs } from "dayjs";
import {
  TAXI_RESERVATION_DROP_AT_DURATION_MINUTES,
  TAXI_RESERVATION_LIMIT_BEFORE_DAY_HOUR,
  TAXI_RESERVATION_LIMIT_DATE,
} from "../global-config";
import { TaxiReservationResponse } from "../openapi/api";

export type TaxiReservationStatus = "draft" | "fixed" | "canceled" | "rejected";
type TaxiReservationOption = {
  status: TaxiReservationStatus;
  name: string;
  color: string;
};
const taxiReservationOptions: TaxiReservationOption[] = [
  {
    status: "draft",
    name: "仮予約",
    color: "#AB5A6D",
  },
  {
    status: "fixed",
    name: "確定",
    color: "#456D3E",
  },
  {
    status: "canceled",
    name: "キャンセル(ユーザーから)",
    color: "#ddd",
  },
  {
    status: "rejected",
    name: "キャンセル(タクシー会社から)",
    color: "#ddd",
  },
];

export const taxiReservationPassengerCountOptions = [
  { value: 1, label: "1名" },
  { value: 2, label: "2名" },
  { value: 3, label: "3名" },
  { value: 4, label: "4名" },
  { value: 5, label: "5名以上" },
];

// 目的地
export const getToLabel = (taxiReservation: TaxiReservationResponse): string =>
  taxiReservation?.endAddress || taxiReservation?.endTaxiLandmark?.name || "";

// 乗車地
export const getFromLabel = (
  taxiReservation: TaxiReservationResponse
): string =>
  taxiReservation?.startAddress ||
  taxiReservation?.startTaxiLandmark?.name ||
  "";

// 表示ユーザー名
export const getUserName = (taxiReservation: TaxiReservationResponse): string =>
  taxiReservation?.userName ||
  taxiReservation?.user?.userInfo?.commonName ||
  "登録なし";

// 表示電話番号
export const getUserPhone = (
  taxiReservation: TaxiReservationResponse
): string =>
  taxiReservation?.userPhone ||
  taxiReservation?.user?.userInfo?.telephone ||
  "登録なし";

export const getStatusName = (taxiReservation: TaxiReservationResponse) => {
  const opt = taxiReservationOptions.find(
    (r) => r.status === taxiReservation.status
  );
  return opt?.name || "";
};

export const getStatusOption = (taxiReservation: TaxiReservationResponse) => {
  const opt = taxiReservationOptions.find(
    (r) => r.status === taxiReservation.status
  );
  return opt || undefined;
};

export const getDefaultDropAt = (dropAt: Dayjs) =>
  dropAt.add(TAXI_RESERVATION_DROP_AT_DURATION_MINUTES, "minutes");

export const getBorderDate = () =>
  dayjs().startOf("day").add(TAXI_RESERVATION_LIMIT_DATE, "days");

export const getLimitBeforeDayTime = () =>
  dayjs().startOf("day").add(TAXI_RESERVATION_LIMIT_BEFORE_DAY_HOUR, "hours");

export const validateTaxiReservationDate = (date: Dayjs): string | null => {
  // 翌日の予約は、前日の17時まで
  const borderNextDayDate = getLimitBeforeDayTime();
  console.log("borderNextDayDate", borderNextDayDate);
  if (
    date.isSame(dayjs().add(1, "day"), "day") &&
    dayjs().isAfter(borderNextDayDate)
  ) {
    return `1日後の予約ができるのは前日17時までです。`;
  }

  // 今日〜N日後以降のみ予約可能
  const borderDate = getBorderDate();
  if (date.isBefore(borderDate)) {
    return `本日より${TAXI_RESERVATION_LIMIT_DATE}日後以降の日程を選択してください。お急ぎの場合は各タクシー会社へ電話にて予約をしてください。`;
  }

  return "";
};

export const getPassangerCountLabel = (passengerCount: number) => {
  const opt = taxiReservationPassengerCountOptions.find(
    (r) => r.value === passengerCount
  );
  return opt?.label || "";
};

// 帰路の日付
export const validateTaxiReservationReturnDate = (
  date: Dayjs,
  rideAt: Dayjs
): string | null => {
  // 今日〜N日後以降のみ予約可能
  const borderDate = getBorderDate();
  if (date.isBefore(borderDate)) {
    return `本日より${TAXI_RESERVATION_LIMIT_DATE}日後以降の日程を選択してください。お急ぎの場合は各タクシー会社へ電話にて予約をしてください。`;
  }

  if (date.isBefore(rideAt.startOf("day"))) {
    return `往路の乗車時間より後の日時を選択してください`;
  }

  return "";
};

// 帰路の時間
export const validateTaxiReservationReturnTime = (
  date: Dayjs,
  rideAt: Dayjs
): string | null => {
  const defaultDropAt = getDefaultDropAt(rideAt);

  if (date.isSameOrBefore(rideAt)) {
    return `往路の乗車時間より後の日時を選択してください`;
  }

  if (date.isSameOrBefore(defaultDropAt)) {
    return `往路の乗車時間より${TAXI_RESERVATION_DROP_AT_DURATION_MINUTES}分後以降から予約可能です`;
  }

  return "";
};
