import { useQueryClient } from '@tanstack/react-query'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import appApiClient from '../hooks/coreApi/appApiClient'
import { useMeQuery } from '../hooks/coreApi/useMeQuery'
import { UserResponse } from '../openapi/api'
import {
  getExpiredLeftTime,
  getIdToken,
  getRefreshToken,
  isValidToken,
  removeSession,
  setSession,
} from '../utils/auth'

type AuthContextType = {
  me: UserResponse | null;
  unreadNotificationCount: number;
  refetchMe: () => void;
  isLoading: boolean;
  isAuthorized: boolean;
};

export const AuthContext = createContext<AuthContextType | null>(null)

type AuthProviderProps = {
  children: React.ReactNode
}

export const AuthProvidor: React.FC<AuthProviderProps> = (props) => {
  const queryClient = useQueryClient()

  const meQuery = useMeQuery({
    withFriends: true,
    withUserInfo: true,
  })

  const [me, setMe] = useState<UserResponse | null>(null)
  const [unreadNotificationCount, setUnreadNotificationCount] = useState<number>(0)

  const logout = () => {
    removeSession()
    setMe(null)
    setUnreadNotificationCount(0)
  }

  // ユーザー情報を取得する
  const refetchMe = async () => {
    const res = await appApiClient.meApi.meDetail({
      withFriends: true,
      withUserInfo: true,
    })
    if (res?.data?.user) {
      setMe(res.data.user)
    }
    if (res?.data?.unreadNotificationCount) {
      setUnreadNotificationCount(res.data.unreadNotificationCount)
    }
  }

  // リフレッシュトークンでトークン更新
  const checkRefreshToken = async () => {
    const idToken = getIdToken()
    if (!idToken) {
      return
    }

    if (isValidToken(idToken)) {
      return
    }

    const refreshToken = getRefreshToken()
    if (!refreshToken) {
      return
    }

    if (!isValidToken(refreshToken)) {
      logout()
      return
    }

    await appApiClient.userApi
      .userToken({
        userTokenRequest: {
          refreshToken,
        },
      })
      .then((res) => {
        if (!res?.data?.token || !res?.data?.refreshToken) {
          return
        }
        setSession(res.data.token, res.data.refreshToken)
        // 認証エラーになっているクエリをリセットして再取得
        queryClient.resetQueries().then(() => {
          refetchMe()
        })
      })
  }

  // ページのロード時にトークンをチェックしてユーザーを設定する
  const initialize = async () => {
    const idToken = getIdToken()
    if (!idToken) {
      logout()
      return
    }

    if (isValidToken(idToken)) {
      refetchMe()
    }
  }

  useEffect(() => {
    // ページのロード時にトークンをチェックしてユーザーを設定する
    initialize()

    // トークン期限切れチェック + リフレッシュトークンでトークン更新
    const timeLeft = 3000 || getExpiredLeftTime()
    const timer = setTimeout(() => {
      checkRefreshToken()
    }, timeLeft)

    // ページのアンロード時にトークン期限切れチェックを解除する
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const memoizedValue = useMemo<AuthContextType>(
    () => ({
      me: me || null,
      unreadNotificationCount: unreadNotificationCount || 0,
      refetchMe,
      isLoading: meQuery.isLoading,
      isAuthorized: !!me,
    }),
    [me],
  )

  return (
    <AuthContext.Provider value={memoizedValue}>
      {props.children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  const context = useContext(AuthContext)

  if (!context)
    throw new Error('useAuthContext context must be use inside AuthProvider')

  return context
}
