import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

type AppBadgeIconProps = BoxProps & {
  size?: string;
  count?: number;
};

const AppBadgeIcon: React.FC<AppBadgeIconProps> = ({
  count,
  size = "20px",
  ...boxProps
}) => (
  <Box
    as="span"
    position="absolute"
    top="-10px"
    right="-10px"
    backgroundColor="red.500"
    color="white"
    borderRadius="full"
    width={size}
    height={size}
    display="flex"
    alignItems="center"
    justifyContent="center"
    fontSize="12px"
    fontWeight="bold"
    {...boxProps}
  >
    {count || ""}
  </Box>
);

export default AppBadgeIcon;
