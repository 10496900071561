import {
  FormControl,
  FormErrorMessage,
  HStack,
  InputProps,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'
import {
  FieldPath,
  FieldValues,
  useController,
  useFormContext,
} from 'react-hook-form'
import AppSelectDate from '../../common/AppSelectDate'
import AppSelectTime from '../../common/AppSelectTime'

export type AppFormDateTimeProps<T extends FieldValues> = InputProps & {
  name: FieldPath<T>
}

const AppFormDateTime = <T extends FieldValues>({
  name,
}: AppFormDateTimeProps<T>) => {
  const { control, getFieldState } = useFormContext()

  const {
    field: { value, onChange },
  } = useController({ name, control })

  const { error } = getFieldState(name)

  const dateValue = useMemo<string>(
    () => dayjs(value).format('YYYY-MM-DD'),
    [value],
  )

  const timeValue = useMemo<string>(() => dayjs(value).format('HH:mm'), [value])

  const onChangeDate = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const dateTimeValue = dayjs(`${evt.target.value}T${timeValue}`)
      onChange(dateTimeValue)
    },
    [value],
  )

  const onChangeTime = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const dateTimeValue = dayjs(`${dateValue}T${evt.target.value}`)
      onChange(dateTimeValue)
    },
    [value],
  )

  return (
    <FormControl isInvalid={!!error?.message}>
      <HStack spacing={2}>
        <AppSelectDate onChange={onChangeDate} value={dateValue} />
        <AppSelectTime onChange={onChangeTime} value={timeValue} />
      </HStack>
      <FormErrorMessage>{error?.message || ''}</FormErrorMessage>
    </FormControl>
  )
}

export default AppFormDateTime
